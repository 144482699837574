import React from "react";
import StyledHero from "../components/StyledHero";
import { graphql } from "gatsby";
import PageWrapper from "../components/Layout/PageWrapper";
import Title from "../components/Title"
import { Container, Col, Row } from "react-bootstrap"
import Pager from "../components/Pager"
import SEO from "../components/seo"
import Blog from "../components/Blog/Blog"

export default class BlogsPage extends React.Component {
  render() {
    const heroImage = this.props.data.blogBcg.childImageSharp.fluid;
    const blogs = this.props.data.blogs.nodes;
    return (
      <PageWrapper>
        <SEO title="Blogs" />
        <StyledHero img={heroImage} />
        <Container fluid>
          <Title title="mijn" subtitle="blogs" />
          <Container fluid className="py-3">
            <Row className="d-flex justify-content-center">
              {blogs.map(blog => {
                return (
                  <Col
                    xs={10}
                    sm={6}
                    md={4}
                    lg={3}
                    className="pb-4"
                    key={blog.id}
                  >
                    <Blog blog={blog} />
                  </Col>
                )
              })}
            </Row>
          </Container>
          <Pager pageContext={this.props.pageContext} page="blog" />
        </Container>
      </PageWrapper>
    )
  }
}

export const query = graphql`
query blogListQuery($skip: Int!, $limit: Int!) {
    blogBcg: file(relativePath: {eq: "blogBcg.jpeg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogs: allContentfulBlog(
        sort: {fields: date, order: DESC}
        skip: $skip
        limit: $limit) {
            nodes {
                id
                slug
                title
                featuredImage {
                  fluid(quality: 100, maxHeight: 750) {
                        srcWebp
                    }
                  title
                }
            }
        }
    } 
`;
